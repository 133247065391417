import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxPermissionsModule } from 'ngx-permissions';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ComponentPreviewerComponent } from './components/component-previewer/component-previewer.component';
import { packageDesignerReducer } from './store/package-designer.reducer';
import { ComponentPreviewerEffects } from './store/component-previewer.effects';
import { XpAliasEditorComponent } from './components/fields-collection/editors/xp-alias-editor.component';
import { CommonModule } from '../common/common.module';
import { XpFieldExpressionEditorComponent } from './components/fields-collection/editors/xp-field-expression-editor.component';
import { XpFieldsCollectionComponent } from './components/fields-collection/xp-fields-collection.component';
import { XpFieldsCollectionHeadersComponent } from './components/fields-collection/xp-fields-collection-headers.component';
import { XpAliasEditorTitleComponent } from './components/fields-collection/editors/xp-alias-editor-title.component';
import { SelectCollectionComponent } from './components/fields-collection/collections/select-collection.component';
import { ResizeColumnDirective } from './components/fields-collection/resize-column.directive';
import { XpFieldsCollectionErrorsBoxComponent } from './components/fields-collection/xp-fields-collection-errors-box.component';
import { FilterCollectionComponent } from './components/fields-collection/collections/filter-collection.component';
import { XpConditionOperatorPickerComponent } from './components/fields-collection/editors/xp-condition-operator-picker.component';
import { AggregateCollectionComponent } from './components/fields-collection/collections/aggregate-collection.component';
import { XpFieldPickerComponent } from './components/fields-collection/editors/xp-field-picker.component';
import { XpFunctionPickerComponent } from './components/fields-collection/editors/xp-function-picker.component';
import { CubeRollupCollectionComponent } from './components/fields-collection/collections/cube-rollup-collection.component';
import { XpCubeRollupPickerComponent } from './components/fields-collection/editors/xp-cube-rollup-picker.component';
import { XpOrderedFieldsPickerComponent } from './components/fields-collection/editors/xp-ordered-fields-picker.component';
import { GroupByCollectionComponent } from './components/fields-collection/collections/group-by-collection.component';
import { JoinCollectionComponent } from './components/fields-collection/collections/join-collection.component';
import { SortCollectionComponent } from './components/fields-collection/collections/sort-collection.component';
import { XpSortDirectionPickerComponent } from './components/fields-collection/editors/xp-sort-direction-picker.component';
import { UnionCollectionComponent } from './components/fields-collection/collections/union-collection.component';
import { WindowCollectionComponent } from './components/fields-collection/collections/window-collection.component';
import { XpWindowArgumentsEditorComponent } from './components/fields-collection/editors/xp-window-arguments-editor.component';
import { RestApiHeadersCollectionComponent } from './components/fields-collection/collections/rest-api-headers-collection.component';
import { XpRestApiHeaderKeyComponent } from './components/fields-collection/editors/xp-rest-api-header-key.component';
import { XpRestApiHeaderValueComponent } from './components/fields-collection/editors/xp-rest-api-header-value.component';
import { SchemaMappingSalesforceCollectionComponent } from './components/fields-collection/collections/schema-mapping-salesforce-collection.component';
import { SchemaMappingDatabaseCollectionComponent } from './components/fields-collection/collections/schema-mapping-database-collection.component';
import { XpCheckboxEditorComponent } from './components/fields-collection/editors/xp-checkbox-editor.component';
import { XpDatabaseColumnEditorComponent } from './components/fields-collection/editors/xp-database-column-editor.component';
import { SchemaMappingNetsuiteCollectionComponent } from './components/fields-collection/collections/schema-mapping-netsuite-collection.component';
import { SchemaMappingFacebookAdsCollectionComponent } from './components/fields-collection/collections/schema-mapping-facebook-ads-collection.component';
import { DesignerModalsComponent } from './components/designer-modals.component';
import { PackageDesignerEffects } from './store/package-designer.effects';
import { CloneEditorComponent } from './components/component-editors/clone-editor.component';
import { ComponentEditorComponent } from './components/component-editor.component';
import { ComponentNameEditorComponent } from './components/component-editors/component-name-editor.component';
import { SelectEditorComponent } from './components/component-editors/select-editor.component';
import { SortEditorComponent } from './components/component-editors/sort-editor.component';
import { RankEditorComponent } from './components/component-editors/rank-editor.component';
import { LimitEditorComponent } from './components/component-editors/limit-editor.component';
import { WindowEditorComponent } from './components/component-editors/window-editor.component';
import { SampleEditorComponent } from './components/component-editors/sample-editor.component';
import { JoinEditorComponent } from './components/component-editors/join-editor.component';
import { ComponentThumbnailComponent } from './components/component-editors-helpers/component-thumbnail.component';
import { ComponentOrderEditorComponent } from './components/component-editors-helpers/component-order-editor.component';
import { CrossJoinEditorComponent } from './components/component-editors/cross-join-editor.component';
import { UnionEditorComponent } from './components/component-editors/union-editor.component';
import { FilterEditorComponent } from './components/component-editors/filter-editor.component';
import { AssertEditorComponent } from './components/component-editors/assert-editor.component';
import { AggregateEditorComponent } from './components/component-editors/aggregate-editor.component';
import { DistinctEditorComponent } from './components/component-editors/distinct-editor.component';
import { CubeEditorComponent } from './components/component-editors/cube-editor.component';
import { DatabaseDestinationEditorComponent } from './components/component-editors/database-destination-editor.component';
import { DatabaseDestinationComponent } from './components/component-editors-helpers/database-destination.component';
import { CodeEditorComponent } from './components/component-editors-helpers/code-editor.component';
import { DatabaseAdvancedOptionsDestinationComponent } from './components/component-editors-helpers/database-advanced-options-destination.component';
import { ConnectionsModule } from '../connections/connections.module';
import { AmazonRedshiftDestinationEditorComponent } from './components/component-editors/amazon-redshift-destination-editor.component';
import { BigQueryDestinationEditorComponent } from './components/component-editors/big-query-destination-editor.component';
import { MongoDestinationEditorComponent } from './components/component-editors/mongo-destination-editor.component';
import { GoogleSpannerDestinationEditorComponent } from './components/component-editors/google-spanner-destination-editor.component';
import { SnowflakeDestinationEditorComponent } from './components/component-editors/snowflake-destination-editor.component';
import { CloudStorageDestinationEditorComponent } from './components/component-editors/cloud-storage-destination-editor.component';
import { CloudStorageDelimiterComponent } from './components/component-editors-helpers/cloud-storage-delimiter.component';
import { FileNamesPatternComponent } from './components/component-editors-helpers/file-names-pattern.component';
import { SalesforceObjectPickerComponent } from './components/component-editors-helpers/salesforce-object-picker.component';
import { SalesforceDestinationEditorComponent } from './components/component-editors/salesforce-destination-editor.component';
import { DatabaseSourceEditorComponent } from './components/component-editors/database-source-editor.component';
import { DatabaseDefinitionComponent } from './components/component-editors-helpers/database-definition.component';
import { SchemaImporterComponent } from './components/component-editors-helpers/schema-importer.component';
import { ConnectionSchemaComponent } from './components/component-editors-helpers/connection-schema.component';
import { SchemaImporterDataPreviewComponent } from './components/component-editors-helpers/schema-importer-data-preview.component';
import { CloudStorageSourceEditorComponent } from './components/component-editors/cloud-storage-source-editor.component';
import { JsonPathComponent } from './components/component-editors-helpers/json-path.component';
import { AmazonRedshiftSourceEditorComponent } from './components/component-editors/amazon-redshift-source-editor.component';
import { BigQuerySourceEditorComponent } from './components/component-editors/big-query-source-editor.component';
import { MongoSourceEditorComponent } from './components/component-editors/mongo-source-editor.component';
import { DateRangeComponent } from './components/component-editors-helpers/date-range.component';
import { FacebookSourceEditorComponent } from './components/component-editors/facebook-source-editor.component';
import { AdwordsSourceEditorComponent } from './components/component-editors/adwords-source-editor.component';
import { AnalyticsSourceEditorComponent } from './components/component-editors/analytics-source-editor.component';
import { RestApiSourceEditorComponent } from './components/component-editors/rest-api-source-editor.component';
import { BingAdsSourceEditorComponent } from './components/component-editors/bing-ads-source-editor.component';
import { NetsuiteSourceEditorComponent } from './components/component-editors/netsuite-source-editor.component';
import { SpannerSourceEditorComponent } from './components/component-editors/spanner-source-editor.component';
import { SalesforceSourceEditorComponent } from './components/component-editors/salesforce-source-editor.component';
import { ExecuteSqlEditorComponent } from './components/component-editors/execute-sql-editor.component';
import { RunPackageEditorComponent } from './components/component-editors/run-package-editor.component';
import { DesignerComponent } from './components/package-designer/designer.component';
import { DesignerPackageItemComponent } from './components/package-designer/designer-package-item.component';
import { DesignerEdgeComponent } from './components/package-designer/designer-edge.component';
import { ComponentsListComponent } from './components/components-list.component';
import { DesignerControlsComponent } from './components/package-designer/designer-controls.component';
import { NoteEditorComponent } from './components/component-editors/note-editor.component';
import { DesignerMapComponent } from './components/package-designer/designer-map.component';
import { DesignerMapEdgeDirective } from './components/package-designer/designer-map-edge.directive';
import { DesignerMapComponentDirective } from './components/package-designer/designer-map-component.directive';
import { PackageJsonImportFormComponent } from './components/package-designer/package-json-import-form.component';
import { PackageVersionMessageFormComponent } from './components/package-designer/package-version-message-form.component';
import { ErrorsViewerComponent } from './components/package-designer/errors-viewer.component';
import { PackageDesignerComponent } from './package-designer.component';
import { PackageDesignerDeactivateGuard } from './package-designer-deactivate.guard';
import { PackageDesignerResolver } from './package-designer.resolver';
import { NetsuiteObjectPickerComponent } from './components/component-editors-helpers/netsuite-object-picker.component';
import { NetsuiteDestinationEditorComponent } from './components/component-editors/netsuite-destination-editor.component';
import { TiktokAdsDestinationEditorComponent } from './components/component-editors/tiktok-ads-destination-editor.component';
import { TiktokAdsObjectPickerComponent } from './components/component-editors-helpers/tiktok-ads-object-picker.component';
import { AccountsCampaignsSelectorListComponent } from './components/component-editors-helpers/accounts-campaigns-selector-list.component';
import { AccountsCampaignsSelectorComponent } from './components/component-editors-helpers/accounts-campaigns-selector.component';
import { SchemaMappingTiktokAdsCollectionComponent } from './components/fields-collection/collections/schema-mapping-tiktok-ads-collection.component';
import { DynamicConnectionComponent } from './components/component-previewer/dynamic-connection.component';
import './services/dynamic-connection.service';
import { FacebookAdsObjectPickerComponent } from './components/component-editors-helpers/facebook-ads-object-picker.component';
import { FacebookAdsDestinationEditorComponent } from './components/component-editors/facebook-ads-destination-editor.component';
import { SalesforceSoapDestinationEditorComponent } from './components/component-editors/salesforce-soap-destination-editor.component';
import { EditComponentProxyComponent } from './edit-package-proxy.component';
import { SchemaMappingGoogleAdsCollectionComponent } from './components/fields-collection/collections/schema-mapping-google-ads-collection.component';
import { GoogleAdsDestinationEditorComponent } from './components/component-editors/google-ads-destination-editor.component';
import { GoogleAdsObjectPickerComponent } from './components/component-editors-helpers/google-ads-object-picker.component';
import { Analytics4SourceEditorComponent } from './components/component-editors/analytics4-source-editor.component';
import { HubspotDestinationEditorComponent } from './components/component-editors/hubspot-destination-editor.component';
import { HubspotObjectPickerComponent } from './components/component-editors-helpers/hubspot-object-picker.component';
import { SchemaMappingHubspotCollectionComponent } from './components/fields-collection/collections/schema-mapping-hubspot-collection.component';
import { FileMoverEditorComponent } from './components/component-editors/file-mover-editor.component';
import { DatabaseTableInputComponent } from './components/component-editors-helpers/database-table-input.component';
import { DatabaseSchemaInputComponent } from './components/component-editors-helpers/database-schema-input.component';
import { MatOption } from '@angular/material/autocomplete';
import { RestApiDestinationEditorComponent } from './components/component-editors/rest-api-destination-editor.component';
import { SchemaMappingRestApiCollectionComponent } from './components/fields-collection/collections/schema-mapping-rest-api-collection.component';
import { XpRadioEditorComponent } from './components/fields-collection/editors/xp-radio-editor.component';
import { XpTypePickerComponent } from './components/fields-collection/editors/xp-type-picker.component';
import { RestApiTransformationEditorComponent } from './components/component-editors/rest-api-transformation-editor.component';
import { RestApiFieldsCollectionComponent } from './components/fields-collection/collections/rest-api-fields-collection.component';
import { RestApiOauthComponent } from './components/fields-collection/collections/rest-api-oauth.component';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [
    ComponentPreviewerComponent,
    DynamicConnectionComponent,
    XpAliasEditorComponent,
    XpWindowArgumentsEditorComponent,
    XpConditionOperatorPickerComponent,
    XpCheckboxEditorComponent,
    XpRadioEditorComponent,
    XpDatabaseColumnEditorComponent,
    XpFieldPickerComponent,
    XpFunctionPickerComponent,
    XpTypePickerComponent,
    XpSortDirectionPickerComponent,
    XpCubeRollupPickerComponent,
    XpOrderedFieldsPickerComponent,
    XpRestApiHeaderKeyComponent,
    XpRestApiHeaderValueComponent,
    XpFieldExpressionEditorComponent,
    XpFieldsCollectionComponent,
    XpFieldsCollectionHeadersComponent,
    XpAliasEditorTitleComponent,
    XpFieldsCollectionErrorsBoxComponent,
    SelectCollectionComponent,
    RestApiFieldsCollectionComponent,
    FilterCollectionComponent,
    AggregateCollectionComponent,
    CubeRollupCollectionComponent,
    GroupByCollectionComponent,
    JoinCollectionComponent,
    SortCollectionComponent,
    UnionCollectionComponent,
    WindowCollectionComponent,
    RestApiHeadersCollectionComponent,
    SchemaMappingSalesforceCollectionComponent,
    SchemaMappingDatabaseCollectionComponent,
    SchemaMappingNetsuiteCollectionComponent,
    SchemaMappingFacebookAdsCollectionComponent,
    SchemaMappingGoogleAdsCollectionComponent,
    SchemaMappingTiktokAdsCollectionComponent,
    GoogleAdsDestinationEditorComponent,
    GoogleAdsObjectPickerComponent,
    AccountsCampaignsSelectorComponent,
    AccountsCampaignsSelectorListComponent,
    ResizeColumnDirective,
    DesignerModalsComponent,
    CloneEditorComponent,
    ComponentEditorComponent,
    ComponentNameEditorComponent,
    SelectEditorComponent,
    SortEditorComponent,
    RankEditorComponent,
    LimitEditorComponent,
    WindowEditorComponent,
    SampleEditorComponent,
    JoinEditorComponent,
    ComponentThumbnailComponent,
    ComponentOrderEditorComponent,
    CrossJoinEditorComponent,
    UnionEditorComponent,
    FilterEditorComponent,
    AssertEditorComponent,
    AggregateEditorComponent,
    DistinctEditorComponent,
    CubeEditorComponent,
    DatabaseDestinationEditorComponent,
    DatabaseDestinationComponent,
    CodeEditorComponent,
    DatabaseAdvancedOptionsDestinationComponent,
    AmazonRedshiftDestinationEditorComponent,
    BigQueryDestinationEditorComponent,
    MongoDestinationEditorComponent,
    GoogleSpannerDestinationEditorComponent,
    SnowflakeDestinationEditorComponent,
    CloudStorageDestinationEditorComponent,
    CloudStorageDelimiterComponent,
    FileNamesPatternComponent,
    SalesforceObjectPickerComponent,
    NetsuiteObjectPickerComponent,
    SalesforceDestinationEditorComponent,
    SalesforceSoapDestinationEditorComponent,
    TiktokAdsObjectPickerComponent,
    DatabaseSourceEditorComponent,
    CloudStorageSourceEditorComponent,
    AmazonRedshiftSourceEditorComponent,
    BigQuerySourceEditorComponent,
    MongoSourceEditorComponent,
    FacebookSourceEditorComponent,
    AdwordsSourceEditorComponent,
    AnalyticsSourceEditorComponent,
    Analytics4SourceEditorComponent,
    RestApiSourceEditorComponent,
    RestApiDestinationEditorComponent,
    RestApiTransformationEditorComponent,
    BingAdsSourceEditorComponent,
    NetsuiteSourceEditorComponent,
    NetsuiteDestinationEditorComponent,
    FacebookAdsDestinationEditorComponent,
    FacebookAdsObjectPickerComponent,
    SpannerSourceEditorComponent,
    SalesforceSourceEditorComponent,
    TiktokAdsDestinationEditorComponent,
    ExecuteSqlEditorComponent,
    FileMoverEditorComponent,
    RestApiOauthComponent,
    RunPackageEditorComponent,
    NoteEditorComponent,
    DesignerComponent,
    DesignerPackageItemComponent,
    DesignerEdgeComponent,
    JsonPathComponent,
    DatabaseDefinitionComponent,
    SchemaImporterComponent,
    ConnectionSchemaComponent,
    DateRangeComponent,
    AccountsCampaignsSelectorComponent,
    AccountsCampaignsSelectorListComponent,
    SchemaImporterDataPreviewComponent,
    ComponentsListComponent,
    DesignerControlsComponent,
    PackageJsonImportFormComponent,
    ErrorsViewerComponent,
    PackageVersionMessageFormComponent,
    PackageDesignerComponent,
    DesignerMapComponent,
    DesignerMapEdgeDirective,
    DesignerMapComponentDirective,
    NetsuiteObjectPickerComponent,
    SchemaMappingNetsuiteCollectionComponent,
    TiktokAdsObjectPickerComponent,
    NetsuiteDestinationEditorComponent,
    FacebookAdsDestinationEditorComponent,
    FacebookAdsObjectPickerComponent,
    SpannerSourceEditorComponent,
    SalesforceSourceEditorComponent,
    TiktokAdsDestinationEditorComponent,
    ExecuteSqlEditorComponent,
    RunPackageEditorComponent,
    NoteEditorComponent,
    DesignerComponent,
    DesignerPackageItemComponent,
    DesignerEdgeComponent,
    JsonPathComponent,
    DatabaseDefinitionComponent,
    SchemaImporterComponent,
    ConnectionSchemaComponent,
    DateRangeComponent,
    SchemaImporterDataPreviewComponent,
    ComponentsListComponent,
    DesignerControlsComponent,
    PackageJsonImportFormComponent,
    ErrorsViewerComponent,
    PackageVersionMessageFormComponent,
    DesignerMapComponent,
    SchemaMappingFacebookAdsCollectionComponent,
    SchemaMappingTiktokAdsCollectionComponent,
    AccountsCampaignsSelectorComponent,
    AccountsCampaignsSelectorListComponent,
    EditComponentProxyComponent,
    HubspotDestinationEditorComponent,
    HubspotObjectPickerComponent,
    SchemaMappingHubspotCollectionComponent,
    DatabaseTableInputComponent,
    DatabaseSchemaInputComponent,
    SchemaMappingRestApiCollectionComponent,
  ],
  exports: [ComponentPreviewerComponent, XpFieldsCollectionErrorsBoxComponent],
  imports: [
    BrowserModule,
    StoreModule.forFeature('packageDesigner', packageDesignerReducer),
    EffectsModule.forFeature([ComponentPreviewerEffects, PackageDesignerEffects]),
    TranslateModule,
    NgbProgressbarModule,
    CommonModule,
    FormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatSortModule,
    MatInputModule,
    DragDropModule,
    MatMenuModule,
    MatTooltipModule,
    ConnectionsModule,
    MatDatepickerModule,
    NgxPermissionsModule,
    MatOption,
  ],
  providers: [PackageDesignerDeactivateGuard, PackageDesignerResolver, provideHttpClient(withInterceptorsFromDi())],
})
export class PackageDesignerModule {}
