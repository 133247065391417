import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { forkJoin, Subject, Subscription } from 'rxjs';
import { uniq } from 'lodash';
import { DesignerSchemaFieldI } from '../../../models/designer-schema-field.model';
import { CollectionSettings } from '../fields-collection.models';
import { NotifyService } from '../../../../common/services/notify.service';
import { ConnectionItemsResource } from '../../../../connections/resources/connection-items.resource';
import { ColumnMap, Schema } from '../../../package.models';
import { findDestinationField, mergeFields } from '../../../helpers/fields.helpers';

interface KeysRequestData {
  [key: string]: {
    keys: DesignerSchemaFieldI[];
  };
}
@Component({
  selector: 'schema-mapping-salesforce-collection',
  template: `
    <xp-fields-collection
      [records]="recordsCopy"
      [collectionSettings]="collectionSettings"
      [isValid]="valid"
      (validityChange)="onFieldsValidityChange($event)"
      (recordsChange)="onRecordChange($event)"
      [columns]="['field_name', 'column_name', 'relationship_name', 'relationship_field_name']"
      duplicationValidationProp="column_name"
      duplicationValidationPropName="Destination column name"
      autofillTooltip="Auto-fill will attempt to map input fields to corresponding Salesforce fields. Manually map or remove all unmapped fields afterward."
    >
      <ng-template templateName="field_name" let-item>
        <xp-field-picker
          [value]="item.record.field_name"
          [index]="item.index"
          [schema]="schemas[0]"
          [fields]="(schemas[0] || {}).fields || []"
          [preventEmpty]="false"
          propName="field_name"
          (fieldChange)="onFieldChange($event, item.record, 'field_name')"
          class="fields-collection-editor"
        ></xp-field-picker>
      </ng-template>
      <ng-template templateName="field_name-header" let-item>
        <span>{{ 'schema-mapping-salesforce.headers.input-field' | translate }}</span>
      </ng-template>

      <ng-template templateName="column_name" let-item>
        <xp-field-picker
          [value]="item.record.column_name"
          [index]="item.index"
          [schema]="schemas[1]"
          [fields]="(schemas[1] || {}).fields || []"
          [preventEmpty]="false"
          [isSalesforceField]="true"
          [isDuplicateError]="item.record.isDuplicateError"
          propName="column_name"
          (fieldChange)="onFieldChange($event, item.record, 'column_name')"
          class="fields-collection-editor"
          [ngClass]="{ readonly: item.record.required, required: item.record.required }"
          fieldName="Destination Field"
        ></xp-field-picker>
      </ng-template>
      <ng-template templateName="column_name-header" let-item>
        <span>{{ 'schema-mapping-salesforce.headers.destination-field' | translate }}</span>
      </ng-template>

      <ng-template templateName="relationship_name" let-item>
        <xp-input
          [ngModel]="item.record.relationship_name"
          (ngModelChange)="onFieldChange($event, item.record, 'relationship_name')"
          state="readonly"
          [id]="'relationship_name' + item.record.id"
          class="fields-collection-editor readonly"
        ></xp-input>
      </ng-template>
      <ng-template templateName="relationship_name-header" let-item>
        <span>{{ 'schema-mapping-salesforce.headers.referenced-object' | translate }}</span>
      </ng-template>

      <ng-template templateName="relationship_field_name" let-item>
        <xp-select
          [value]="item.record.relationship_field_name"
          (valueChange)="onFieldChange($event, item.record, 'relationship_field_name')"
          [options]="item.record.relationKeys"
          valueKey="name"
          [id]="'relationship_field_name' + item.record.id"
          class="fields-collection-editor"
          [ngClass]="{ readonly: !item.record.relationKeys || item.record.relationKeys.length === 0 }"
        ></xp-select>
      </ng-template>
      <ng-template templateName="relationship_field_name-header" let-item>
        <span>{{ 'schema-mapping-salesforce.headers.referenced-field' | translate }}</span>
      </ng-template>
    </xp-fields-collection>
    <div class="required-fields-info" *ngIf="isAnyFieldRequired">* - Required field</div>
  `,
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchemaMappingSalesforceCollectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() records: DesignerSchemaFieldI[];
  @Input() valid: boolean;
  @Input() schemas: Schema[];
  @Input() fields: DesignerSchemaFieldI[];
  @Input() operationType: string;
  @Input() idField: string;
  @Input() objectName: string;
  @Input() connectionType: string = 'salesforce';
  @Input() connectionId: number | string;
  @Input() schemaMappingStepActivationSubject: Subject<any>;
  @Output() recordsChange = new EventEmitter();
  @Output() validityChange = new EventEmitter();

  oldIdField = '';

  collectionSettings: CollectionSettings;

  recordsCopy: DesignerSchemaFieldI[] = [];
  schemaMappingStepActivationSubjectSubscription: Subscription;

  constructor(
    private connectionItemsResource: ConnectionItemsResource,
    private notify: NotifyService,
  ) {}

  ngOnInit() {
    this.recordsCopy = [...this.records].map((item) => ({ ...item, id: uuidv4() }));

    const defaultCollectionSettings: CollectionSettings = {
      itemsPerPage: 10,
      emptyRecord: {
        field_name: '',
        column_name: '',
        relationship_name: '',
        field_type: '',
        FC_pristine: true,
      },
      parentSchemas: this.schemas,
      autoFillFns: [
        {
          func: this.autoFill.bind(this),
          text: 'Auto-fill',
        },
      ],
    };

    this.collectionSettings = { ...(this.collectionSettings || {}), ...defaultCollectionSettings };

    if (this.recordsCopy.length === 0) {
      this.recordsCopy = ((this.schemas[0] || {}).fields || []).map((field) => ({
        field_name: field.name,
        id: uuidv4(),
        FC_pristine: false,
      }));
    }

    if (this.schemaMappingStepActivationSubject) {
      this.schemaMappingStepActivationSubjectSubscription = this.schemaMappingStepActivationSubject.subscribe(() => {
        setTimeout(() => {
          this.autoFillRecords();
        });
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.records && changes.records.isFirstChange()) || Object.isFrozen(this.recordsCopy)) {
      this.recordsCopy = [...this.records].map((item) => ({ ...item, id: uuidv4() }));
    }

    if (changes.idField) {
      const newField = changes.idField.currentValue;
      const oldField = changes.idField.previousValue;

      if ((this.operationType === 'upsert' || this.operationType === 'update') && newField !== oldField) {
        const record = this.recordsCopy.find((item) => item.column_name === newField);
        if (!record) {
          this.addFieldByName(newField, true, true);
        } else {
          this.recordsCopy = this.recordsCopy.map((item) => {
            return item.column_name === newField ? { ...item, required: true } : item;
          });
        }
        const lastRecord = this.recordsCopy.find((item) => item.column_name === oldField);
        if (lastRecord) {
          this.recordsCopy = this.recordsCopy.map((field) =>
            field.id === lastRecord.id ? { ...field, required: false } : field,
          );
        }
      }

      if (!newField) {
        this.oldIdField = oldField;
      }
    }

    if (changes.operationType && this.fields) {
      this.onOperationTypeChange(changes.operationType.currentValue, changes.operationType.previousValue);
    }

    if (changes.fields && changes.fields.currentValue) {
      this.removeOldRequiredFields(changes.fields);
      this.loadKeys(this.fields).subscribe({
        next: (data: KeysRequestData) => {
          this.fields = this.fields.map((field) => {
            if (field.reference_to && field.reference_to[0] && data[field.reference_to[0]]) {
              return {
                relationKeys: data[field.reference_to[0]].keys,
                ...field,
              };
            }
            return field;
          });
          this.fillRecords();
          this.fillParentSchemas();
          this.setFieldsReferences();
        },
        error: () => {
          this.notify.error('Error occurred while loading keys');
        },
      });
    }
  }

  onOperationTypeChange(newOperationType: string, oldOperationType: string) {
    this.fillParentSchemas(true);
    if (newOperationType !== oldOperationType) {
      if (oldOperationType === 'insert') {
        this.recordsCopy = this.recordsCopy.map((item) => ({ ...item, required: false }));
      }

      if (oldOperationType === 'upsert' || oldOperationType === 'update') {
        if (
          this.oldIdField &&
          this.fields.length &&
          !this.fields.find((field) => field.name === this.oldIdField).is_required
        ) {
          const record = this.recordsCopy.find((item) => item.column_name === this.oldIdField);
          if (record) {
            this.recordsCopy = this.recordsCopy.map((item) =>
              item.column_name === this.oldIdField ? { ...item, required: false } : item,
            );
            this.onRecordChange({ records: this.recordsCopy });
          }
          this.oldIdField = '';
        }
      } else if (oldOperationType === 'delete' || oldOperationType === 'hardDelete') {
        this.recordsCopy[0].required = false;
      }

      if (newOperationType === 'upsert' || newOperationType === 'update') {
        const keyableField = this.fields.find((field) => field.is_keyable === true);
        if (!this.idField && keyableField) {
          this.idField = keyableField.name;
        }

        setTimeout(() => {
          const record = this.recordsCopy.find((item) => item.column_name === this.idField);
          if (!record) {
            this.addFieldByName(this.idField, true, true);
          } else {
            this.recordsCopy = this.recordsCopy.map((item) =>
              item.column_name === this.idField ? { ...item, required: true } : item,
            );
            this.onRecordChange({ records: this.recordsCopy });
          }
        });
      }

      if (newOperationType === 'delete' || newOperationType === 'hardDelete') {
        const field = this.fields.find((item) => item.type === 'ID');
        let parent_field_name = '';
        if (this.schemas && this.schemas[0] && this.schemas[0]?.fields && this.schemas[0].fields[0]) {
          parent_field_name = this.schemas[0].fields[0].name;
        }
        const record = {
          required: true,
          addDisabled: true,
          field_name: parent_field_name,
          column_name: (field || {}).name || '',
          relationship_name: '',
          field_type: (field || {}).type || '',
          id: uuidv4(),
        };
        this.recordsCopy = [record];
        this.recordsChange.emit(this.recordsCopy);
      }
    }
  }

  setFieldsReferences() {
    this.recordsCopy = this.recordsCopy.map((record) => ({ ...record }));
    this.recordsCopy.forEach((record) => {
      if (record.field_type === 'REFERENCE' || record.field_type === 'PICKLIST') {
        this.setReference(record.column_name);
      }
    });

    this.recordsChange.emit(this.recordsCopy);
  }

  fillRecords() {
    const requiredFields = {};
    this.recordsCopy = this.recordsCopy.map((record) => {
      let required;
      if (!requiredFields[record.column_name]) {
        if (this.operationType === 'upsert' || this.operationType === 'update') {
          if (!requiredFields[record.column_name] && record.column_name === this.idField) {
            required = true;
            requiredFields[record.column_name] = true;
          }
        } else if (this.operationType === 'delete' || this.operationType === 'hardDelete') {
          required = true;
          requiredFields[record.column_name] = true;
        } else {
          const field = this.fields.find((item) => item.name === record.column_name);
          if (field && field.is_required) {
            required = true;
            requiredFields[record.column_name] = true;
          } else {
            required = false;
          }
        }
      }
      return {
        ...record,
        required,
      };
    });

    this.recordsChange.emit(this.recordsCopy);
  }

  removeOldRequiredFields({ currentValue, previousValue }: SimpleChange) {
    setTimeout(() => {
      this.recordsCopy = this.recordsCopy.filter((item) => {
        return !(item.required && !currentValue.find((field) => field.name === item.column_name));
      });
      this.recordsChange.emit(this.recordsCopy);
    });
  }

  fillParentSchemas(setRequiredReference?: boolean) {
    const destinationSchema = {} as Schema;
    destinationSchema.id = `salesforce_fields_${this.objectName}`;
    destinationSchema.name = `salesforce_fields_${this.objectName}`;

    const objectsSchema = {} as Schema;
    objectsSchema.id = `objects_fields_${this.objectName}`;
    objectsSchema.name = `objects_fields_${this.objectName}`;

    this.fields.forEach((field) => {
      destinationSchema.fields = [
        ...(destinationSchema.fields || []),
        {
          name: field.name,
        },
      ];
      if (field && field.is_required) {
        const isFieldExistingFromRecordsIndex: number = this.recordsCopy.findIndex(
          (item) => item.column_name === field.name,
        );
        const isFieldExistingFromDestinationSchemaIndex: number = destinationSchema.fields.findIndex(
          (item) => item.column_name === field.name,
        );

        if (isFieldExistingFromRecordsIndex === -1 && isFieldExistingFromDestinationSchemaIndex === -1) {
          if (this.operationType === 'insert') {
            let parent_field_name = '';

            if (this.schemas[0]) {
              const foundField = this.schemas[0].fields.find(
                (item) => item.name.toLowerCase() === field.name.toLowerCase(),
              );
              if (foundField) {
                parent_field_name = foundField.name;
              }
            }

            this.recordsCopy = this.recordsCopy.filter((item) => item.column_name !== (field || {}).name);

            if (parent_field_name) {
              this.recordsCopy = this.recordsCopy.filter((item) => item.field_name !== parent_field_name);
            }

            this.recordsCopy = [
              {
                required: true,
                field_name: parent_field_name,
                column_name: (field || {}).name || '',
                relationship_name: '',
                field_type: (field || {}).type || '',
                id: uuidv4(),
                FC_pristine: false,
              },
              ...this.recordsCopy,
            ];
            if (setRequiredReference && (field.type === 'REFERENCE' || field.type === 'PICKLIST')) {
              this.setReference(field.name);
            }
          }
        } else {
          if (isFieldExistingFromRecordsIndex) {
            this.recordsCopy = [...this.recordsCopy];
            this.recordsCopy[isFieldExistingFromRecordsIndex] = {
              ...this.recordsCopy[isFieldExistingFromRecordsIndex],
              required: this.isRequired(),
            };
          }
          if (isFieldExistingFromDestinationSchemaIndex) {
            destinationSchema.fields = [...destinationSchema.fields];
            destinationSchema.fields[isFieldExistingFromDestinationSchemaIndex] = {
              ...this.recordsCopy[isFieldExistingFromDestinationSchemaIndex],
              required: this.isRequired(),
            } as any;
          }
        }
      }
    });

    this.schemas = [this.schemas[0], destinationSchema, objectsSchema];
  }

  setReference(value: string) {
    const record = this.recordsCopy.find((item) => item.column_name === value);
    if (record) {
      const field = this.fields.find((item) => item.name === value);
      if (field) {
        record.field_type = field.type;
        if ((field.type === 'REFERENCE' || field.type === 'PICKLIST') && field.reference_to && field.reference_to[0]) {
          record.relationKeys = field.relationKeys;
          // eslint-disable-next-line prefer-destructuring
          record.relationship_name = field.reference_to[0];
          record.objectField = field;
          if (!record.relationship_field_name)
            record.relationship_field_name =
              field.relationKeys && field.relationKeys.length ? field.relationKeys[0].name : '';
          return;
        }
      }
      record.relationKeys = [];
      record.relationship_name = '';
      record.relationship_field_name = '';
    }
  }

  autoFill() {
    if (!this.schemas[0] || !this.schemas[0].fields) {
      return;
    }

    const records: DesignerSchemaFieldI[] = [];
    mergeFields(this.recordsCopy, this.schemas[0].fields).forEach((field) => {
      const fieldName = (field as DesignerSchemaFieldI).field_name || field.name || '';
      const fieldDestination =
        (findDestinationField(fieldName || field.column_name, this.fields || [], true) as DesignerSchemaFieldI) || {};
      const record: DesignerSchemaFieldI = {
        ...field,
        FC_pristine: false,
      };
      if (!record.field_name) {
        record.field_name = fieldName;
      }
      if (!record.id) {
        record.id = uuidv4();
      }

      if (record.column_name && !record.field_name) {
        const inputField = findDestinationField(record.column_name, this.schemas[0].fields, true);
        record.field_name = inputField?.name || '';
      }

      if (!record.required) {
        if (fieldDestination.name) {
          record.column_name = fieldDestination.name;
          record.field_type = fieldDestination.type;
        }

        if (
          (fieldDestination.type === 'REFERENCE' || fieldDestination.type === 'PICKLIST') &&
          fieldDestination.reference_to?.length
        ) {
          record.relationKeys = fieldDestination.relationKeys;
          // eslint-disable-next-line prefer-destructuring
          record.relationship_name = fieldDestination.reference_to[0];
          if (fieldDestination.relationKeys && fieldDestination.relationKeys[0]) {
            record.relationship_field_name = fieldDestination.relationKeys[0].name;
          }
        }

        if (this.operationType === 'insert') {
          record.required = fieldDestination.is_required && this.isRequired();
        } else {
          record.required = this.idField && fieldName.toLowerCase() === this.idField.toLowerCase();
        }
      }

      if (!!record.column_name && records.find((item) => item.column_name === record.column_name)) {
        return;
      }

      records.push(record);
    });
    this.recordsCopy = records;
    this.recordsChange.emit(records);

    return records;
  }

  isRequired() {
    return this.operationType !== 'upsert' && this.operationType !== 'update';
  }

  loadRelationKeys(object_name: string) {
    return this.connectionItemsResource.relationKeys(this.connectionType, this.connectionId, {
      object_name,
    } as any);
  }

  loadKeys(fields: DesignerSchemaFieldI[]) {
    const keysToRequest = uniq(fields.filter((field) => field.reference_to).map((field) => field.reference_to[0]));
    const requestsMap = keysToRequest.reduce((acc, key) => ({ ...acc, [key]: this.loadRelationKeys(key) }), {});

    return forkJoin(requestsMap);
  }

  addFieldByName(fileldName, addFirst, isRequired) {
    const field = this.fields.find((item) => item.name === fileldName);
    let parent_field_name = '';
    if (this.schemas[0] && this.schemas[0].fields[0]) {
      parent_field_name = this.schemas[0].fields[0].name;
    }
    if (field) {
      const newRecord = {
        required: isRequired,
        field_name: parent_field_name,
        column_name: field.name,
        field_type: field.type,
        id: uuidv4(),
      };
      if (addFirst) {
        this.recordsCopy = [newRecord, ...this.recordsCopy];
      } else {
        this.recordsCopy = [...this.recordsCopy, newRecord];
      }
    }
    this.recordsChange.emit(this.recordsCopy);
  }

  onRecordChange({ records }) {
    if (!this.fields) {
      return;
    }
    this.recordsCopy = [...records];
    const columnMappings = records.map((record) => {
      const field: ColumnMap = {
        ...record,
      };
      const objectField = this.fields.find((item) => item.name === record.column_name);
      if (
        objectField &&
        (objectField.type === 'REFERENCE' || objectField.type === 'PICKLIST') &&
        objectField.relationKeys
      ) {
        field.objectField = objectField;
      }
      return field;
    });

    this.recordsChange.emit(columnMappings);
  }

  onFieldChange(value: string, record: DesignerSchemaFieldI, prop: keyof DesignerSchemaFieldI) {
    if (!this.fields || value === undefined || record[prop] === value) {
      return;
    }
    const newRecords = this.recordsCopy.map((item) => {
      if (item.id === record.id) {
        if (prop === 'column_name') {
          const field = (this.fields || []).find((fieldItem) => fieldItem.name === value);

          return {
            ...item,
            [prop]: value,
            field_type: field.type,
          };
        }
        return {
          ...item,
          [prop]: value,
        };
      }
      return item;
    });
    this.recordsCopy = newRecords;

    this.onRecordChange({
      records: newRecords,
    });

    if (prop === 'column_name') {
      this.setReference(value);
    }
  }

  onFieldsValidityChange(value: boolean) {
    this.validityChange.emit(value);
  }

  autoFillRecords() {
    const areRecordsEmpty = this.recordsCopy
      .filter((record) => !record.required)
      .every((record) => !record.column_name);

    if (areRecordsEmpty) {
      this.autoFill();
    }
  }

  isAnyFieldRequired() {
    return this.recordsCopy.some((record) => record.required);
  }

  ngOnDestroy() {
    if (this.schemaMappingStepActivationSubjectSubscription) {
      this.schemaMappingStepActivationSubjectSubscription.unsubscribe();
    }
  }
}
