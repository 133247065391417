import { CLUSTERS_VISIBLE_STATUSES } from '../../clusters/clusters.models';

export interface ListParams {
  limit?: number;
  sort?: string;
  direction?: string;
  include?: string;
  status?: string;
  exclude?: string;
  offset?: number;
  q?: string;
  package_id?: number;
  sort_locally?: string;
  name?: string;
  description?: string;
  packages?: string;
  type?: string;
  ignoreLoadingFlag?: boolean;
  flow_type?: string;
  current_package?: number;
}

export interface CustomHeaders {
  ignorenotify?: string;
}

export const QueryParamsJobsList: ListParams = {
  limit: 20,
  sort: 'created',
  direction: 'desc',
  include: 'owner,package,cluster',
  exclude: 'outputs,variables',
};

export const QueryParamsMembersList: ListParams = {
  sort: 'name',
  direction: 'asc',
  limit: 20,
  offset: 0,
};

export const QueryParamsKeysList: ListParams = {
  limit: 20,
  sort: 'name',
  direction: 'asc',
};

export const QueryParamsHooksList: ListParams = {
  limit: 20,
  sort: 'name',
  direction: 'asc',
};

export const QueryParamsAccountsList: ListParams = {
  limit: 20,
  sort: 'name',
  direction: 'asc',
};

export const QueryParamsAccountsMenuList = {
  sort: 'name',
  direction: 'asc',
  offset: 0,
  limit: 100,
};

export const QueryParamsClustersList: ListParams = {
  limit: 100,
  sort: 'created',
  direction: 'desc',
  include: 'owner',
  status: CLUSTERS_VISIBLE_STATUSES.join(','),
};

export const QueryParamsSchedulesList: ListParams = {
  limit: 20,
  sort: 'updated',
  direction: 'desc',
  include: 'owner',
};

export const QueryParamsWorkspacesList: ListParams = {
  limit: 8,
  sort: 'updated',
  direction: 'desc',
  include: 'owner,packages',
};

export const QueryParamsConnectionsList: ListParams = {
  limit: 20,
  sort: 'updated',
  direction: 'desc',
  include: 'owner',
};

export const QueryParamsPackagesList: any = {
  limit: 20,
  sort: 'updated',
  direction: 'desc',
  include: 'owner,workspace',
};
