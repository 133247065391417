import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { AnyComponentData, Component as PackageComponent } from '../package.models';
import { COMPONENT_TYPE } from '../../constants/component_type';
import { AppState } from '../../store';
import { closeComponentsModal } from '../store/package-designer.actions';
import { updateComponentInPackage } from '../store/package.actions';
import { COMPONENT_CATEGORY, ComponentTypeItem } from '../../constants/component_types';
import {
  selectComponentFormValidFlag,
  selectComponentParents,
  selectComponentParentSchemas,
} from '../store/package-designer.selectors';
import { setComponentNameFormValidity, updateComponent, updateRawComponent } from '../store/component.actions';
import { selectLastlyCreatedConnection } from '../../connections/store/connections.selectors';
import { AnyConnection } from '../../connections/connection.models';
import { loadSelectPickerItems } from '../../common/store/select-picker.actions';
import {
  queryParamsMap,
  SelectPickerTypes,
} from '../../common/components/forms/select-picker/select-picker-types.enum';
import { PermissionsService } from '../../common/services/permissions.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'component-editor',
  template: `
    <div class="component-editor">
      <div class="component-editor-body">
        <component-name-editor
          [rawComponent]="rawComponent"
          [component]="component"
          (formValidationChange)="onFormValidationChange($event)"
          *ngIf="component.type !== COMPONENT_CATEGORY.note"
        ></component-name-editor>

        <div class="note-editor" *ngIf="component.type === COMPONENT_CATEGORY.note">
          <note-editor [rawComponent]="rawComponent"></note-editor>
        </div>

        <div
          class="component-editor-transformation"
          *ngIf="component.type === COMPONENT_CATEGORY.transformation"
          [ngSwitch]="component.componentType"
        >
          <clone-editor *ngSwitchCase="componentTypes.CLONE_COMPONENT"></clone-editor>

          <distinct-editor
            *ngSwitchCase="componentTypes.DISTINCT_COMPONENT"
            [rawComponent]="rawComponent"
          ></distinct-editor>
          <select-editor
            *ngSwitchCase="componentTypes.SELECT_COMPONENT"
            [rawComponent]="rawComponent"
            [parentSchemas]="componentParentSchemas$ | async"
          ></select-editor>
          <sort-editor
            *ngSwitchCase="componentTypes.SORT_COMPONENT"
            [rawComponent]="rawComponent"
            [parentSchemas]="componentParentSchemas$ | async"
          ></sort-editor>
          <rank-editor
            *ngSwitchCase="componentTypes.RANK_COMPONENT"
            [rawComponent]="rawComponent"
            [parentSchemas]="componentParentSchemas$ | async"
          ></rank-editor>
          <limit-editor
            *ngSwitchCase="componentTypes.LIMIT_COMPONENT"
            [rawComponent]="rawComponent"
            [parentSchemas]="componentParentSchemas$ | async"
          ></limit-editor>
          <window-editor
            *ngSwitchCase="componentTypes.WINDOW_COMPONENT"
            [rawComponent]="rawComponent"
            [parentSchemas]="componentParentSchemas$ | async"
          ></window-editor>
          <sample-editor
            *ngSwitchCase="componentTypes.SAMPLE_COMPONENT"
            [rawComponent]="rawComponent"
            [parentSchemas]="componentParentSchemas$ | async"
          ></sample-editor>
          <join-editor
            *ngSwitchCase="componentTypes.JOIN_COMPONENT"
            [rawComponent]="rawComponent"
            [parentSchemas]="componentParentSchemas$ | async"
            [parentComponents]="componentParents$ | async"
            (componentsOrderChange)="componentsOrderChange($event)"
          ></join-editor>
          <cross-join-editor
            *ngSwitchCase="componentTypes.CROSS_JOIN_COMPONENT"
            [rawComponent]="rawComponent"
            [parentSchemas]="componentParentSchemas$ | async"
            [parentComponents]="componentParents$ | async"
            (componentsOrderChange)="componentsOrderChange($event)"
          ></cross-join-editor>
          <union-editor
            *ngSwitchCase="componentTypes.UNION_COMPONENT"
            [rawComponent]="rawComponent"
            [parentSchemas]="componentParentSchemas$ | async"
            [parentComponents]="componentParents$ | async"
            (componentsOrderChange)="componentsOrderChange($event)"
          ></union-editor>
          <filter-editor
            *ngSwitchCase="componentTypes.FILTER_COMPONENT"
            [rawComponent]="rawComponent"
            [parentSchemas]="componentParentSchemas$ | async"
          ></filter-editor>
          <assert-editor
            *ngSwitchCase="componentTypes.ASSERT_COMPONENT"
            [rawComponent]="rawComponent"
            [parentSchemas]="componentParentSchemas$ | async"
          ></assert-editor>
          <aggregate-editor
            *ngSwitchCase="componentTypes.AGGREGATE_COMPONENT"
            [rawComponent]="rawComponent"
            [parentSchemas]="componentParentSchemas$ | async"
          ></aggregate-editor>
          <cube-editor
            *ngSwitchCase="componentTypes.CUBE_COMPONENT"
            [rawComponent]="rawComponent"
            [parentSchemas]="componentParentSchemas$ | async"
          ></cube-editor>
          <rest-api-transformation-editor
            *ngSwitchCase="componentTypes.REST_API_TRANSFORMATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></rest-api-transformation-editor>
        </div>

        <div
          class="destination"
          *ngIf="component.type === COMPONENT_CATEGORY.destination"
          [ngSwitch]="component.componentType"
        >
          <database-destination-editor
            *ngSwitchCase="componentTypes.DATABASE_DESTINATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></database-destination-editor>
          <amazon-redshift-destination-editor
            *ngSwitchCase="componentTypes.AMAZON_REDSHIFT_DESTINATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></amazon-redshift-destination-editor>
          <big-query-destination-editor
            *ngSwitchCase="componentTypes.BIG_QUERY_DESTINATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></big-query-destination-editor>
          <mongo-destination-editor
            *ngSwitchCase="componentTypes.MONGO_DESTINATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></mongo-destination-editor>
          <google-spanner-destination-editor
            *ngSwitchCase="componentTypes.SPANNER_DESTINATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></google-spanner-destination-editor>
          <snowflake-destination-editor
            *ngSwitchCase="componentTypes.SNOWFLAKE_DESTINATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></snowflake-destination-editor>
          <cloud-storage-destination-editor
            *ngSwitchCase="componentTypes.CLOUD_STORAGE_DESTINATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></cloud-storage-destination-editor>
          <salesforce-destination-editor
            *ngSwitchCase="componentTypes.SALESFORCE_DESTINATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></salesforce-destination-editor>
          <salesforce-soap-destination-editor
            *ngSwitchCase="componentTypes.SALESFORCE_SOAP_DESTINATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></salesforce-soap-destination-editor>
          <netsuite-destination-editor
            *ngSwitchCase="componentTypes.NETSUITE_DESTINATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></netsuite-destination-editor>
          <google-ads-destination-editor
            *ngSwitchCase="componentTypes.GOOGLE_ADS_DESTINATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></google-ads-destination-editor>
          <facebook-ads-destination-editor
            *ngSwitchCase="componentTypes.FACEBOOK_ADS_DESTINATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></facebook-ads-destination-editor>
          <hubspot-destination-editor
            *ngSwitchCase="componentTypes.HUBSPOT_DESTINATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></hubspot-destination-editor>
          <tiktok-ads-destination-editor
            *ngSwitchCase="componentTypes.TIKTOK_ADS_DESTINATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></tiktok-ads-destination-editor>
          <rest-api-destination-editor
            *ngSwitchCase="componentTypes.REST_API_DESTINATION_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></rest-api-destination-editor>
        </div>

        <div
          class="component-editor-source"
          *ngIf="component.type === COMPONENT_CATEGORY.source"
          [ngSwitch]="component.componentType"
        >
          <database-source-editor
            *ngSwitchCase="componentTypes.DATABASE_SOURCE_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></database-source-editor>
          <cloud-storage-source-editor
            *ngSwitchCase="componentTypes.CLOUD_STORAGE_SOURCE_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></cloud-storage-source-editor>
          <amazon-redshift-source-editor
            *ngSwitchCase="componentTypes.AMAZON_REDSHIFT_SOURCE_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></amazon-redshift-source-editor>
          <big-query-source-editor
            *ngSwitchCase="componentTypes.BIG_QUERY_SOURCE_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></big-query-source-editor>
          <mongo-source-editor
            *ngSwitchCase="componentTypes.MONGO_SOURCE_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></mongo-source-editor>
          <facebook-source-editor
            *ngSwitchCase="componentTypes.FACEBOOK_ADS_INSIGHTS_SOURCE_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></facebook-source-editor>
          <adwords-source-editor
            *ngSwitchCase="componentTypes.ADWORDS_SOURCE_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></adwords-source-editor>
          <analytics-source-editor
            *ngSwitchCase="componentTypes.ANALYTICS_SOURCE_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></analytics-source-editor>
          <analytics4-source-editor
            *ngSwitchCase="componentTypes.ANALYTICS_GA4_SOURCE_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></analytics4-source-editor>
          <rest-api-source-editor
            *ngSwitchCase="componentTypes.REST_API_SOURCE_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></rest-api-source-editor>
          <bing-ads-source-editor
            *ngSwitchCase="componentTypes.BING_ADS_SOURCE_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></bing-ads-source-editor>
          <netsuite-source-editor
            *ngSwitchCase="componentTypes.NET_SUITE_SOURCE_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></netsuite-source-editor>
          <spanner-source-editor
            *ngSwitchCase="componentTypes.SPANNER_SOURCE_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></spanner-source-editor>
          <salesforce-source-editor
            *ngSwitchCase="componentTypes.SALESFORCE_SOURCE_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></salesforce-source-editor>
        </div>

        <div
          class="component-editor-execute-sql"
          *ngIf="
            component.componentType === componentTypes.RUN_PACKAGE_COMPONENT ||
            component.componentType === componentTypes.EXECUTE_SQL_COMPONENT ||
            component.componentType === componentTypes.FILE_MOVER_COMPONENT
          "
          [ngSwitch]="component.componentType"
        >
          <execute-sql-editor
            *ngSwitchCase="componentTypes.EXECUTE_SQL_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></execute-sql-editor>
          <run-package-editor
            *ngSwitchCase="componentTypes.RUN_PACKAGE_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></run-package-editor>
          <file-mover-editor
            *ngSwitchCase="componentTypes.FILE_MOVER_COMPONENT"
            [rawComponent]="rawComponent"
            [component]="component"
            [parentSchemas]="componentParentSchemas$ | async"
            (createConnection)="onCreateConnection($event)"
          ></file-mover-editor>
        </div>
      </div>
      <div class="component-editor-footer modal-footer">
        <div class="component-editor-footer-icon">
          <common-icon
            [iconId]="component.type !== COMPONENT_CATEGORY.note ? component.icon : 'icon-note'"
            size="L"
          ></common-icon>
        </div>
        <div class="component-editor-footer-title">
          <h3>
            <span *ngIf="isNew">New </span>
            <span *ngIf="!isNew">Edit </span>
            <span class="component-name" *ngIf="component.type !== COMPONENT_CATEGORY.note"
              >{{ component.originalName }}{{ component.type === 'workflow' ? ' task' : ' ' + component.type }}</span
            >
            <span class="component-name" *ngIf="component.type === COMPONENT_CATEGORY.note">{{
              component.originalName
            }}</span>
          </h3>
        </div>
        <div class="component-editor-footer-button">
          <button
            [ngClass]="{
              hidden: !(hasPermission$('updatePackage') | async)
            }"
            class="btn btn-lg btn-default"
            (click)="closeModal()"
          >
            Cancel
          </button>
          <button
            [ngClass]="{
              hidden: hasPermission$('updatePackage') | async
            }"
            class="btn btn-lg btn-default"
            (click)="closeModal()"
          >
            Close
          </button>
          <button
            class="btn btn-lg"
            [ngClass]="{
              'btn-success': isComponentFormValid,
              hidden: !(hasPermission$('updatePackage') | async)
            }"
            [disabled]="!isComponentFormValid"
            (click)="saveAndCloseModal()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  `,
})
export class ComponentEditorComponent implements OnInit, OnDestroy {
  @Input() rawComponent: AnyComponentData;
  @Input() component: ComponentTypeItem;
  @Output() createConnection = new EventEmitter();

  componentParentSchemas$ = this.store.select(selectComponentParentSchemas);
  componentParents$ = this.store.select(selectComponentParents);

  isNew = false;
  componentTypes = COMPONENT_TYPE;
  COMPONENT_CATEGORY = COMPONENT_CATEGORY;
  isComponentFormValid = false;
  changedComponents: PackageComponent[];
  lastlyCreatedConnectionSubscription: Subscription;
  componentFormValidSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private permissionsService: PermissionsService,
  ) {}

  ngOnInit() {
    if (environment.SENTRY_ENABLED) {
      Sentry.addBreadcrumb({
        message: 'Component editor open: ',
        level: 'info',
        category: 'component-editor',
        data: {
          component: this.rawComponent,
        },
      });
    }

    this.lastlyCreatedConnectionSubscription = this.store
      .select(selectLastlyCreatedConnection)
      .pipe(filter(Boolean))
      .subscribe((connection: AnyConnection) => {
        loadSelectPickerItems({
          id: 'connection-picker-component',
          itemType: SelectPickerTypes.connection,
          params: {
            ...queryParamsMap[SelectPickerTypes.connection],
            type: this.component.connectionTypes,
            ignoreLoadingFlag: true,
          },
        });

        this.store.dispatch(
          updateRawComponent({
            rawComponent: { connection },
          }),
        );
        this.store.dispatch(updateComponent({ component: { connection } }));
      });

    this.componentFormValidSubscription = this.store.select(selectComponentFormValidFlag).subscribe({
      next: (isComponentValid) => {
        setTimeout(() => {
          this.isComponentFormValid = isComponentValid;
        });
      },
    });
  }

  closeModal() {
    this.store.dispatch(closeComponentsModal());
  }

  componentsOrderChange(components: PackageComponent[]) {
    this.changedComponents = components;
  }

  saveAndCloseModal() {
    this.store.dispatch(updateComponentInPackage({ changedComponentsOrder: this.changedComponents }));
    this.store.dispatch(closeComponentsModal());
  }

  onFormValidationChange(isFormValid: boolean) {
    this.store.dispatch(setComponentNameFormValidity({ isComponentNameFormValid: isFormValid }));
  }

  onCreateConnection(params) {
    this.createConnection.emit(params);
  }

  hasPermission$(permissionName) {
    return this.permissionsService.hasPermission$(permissionName);
  }

  ngOnDestroy() {
    if (this.lastlyCreatedConnectionSubscription) {
      this.lastlyCreatedConnectionSubscription.unsubscribe();
    }

    if (this.componentFormValidSubscription) {
      this.componentFormValidSubscription.unsubscribe();
    }
  }
}
