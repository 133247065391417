
export const environment = {
      production: true,
      ACCOUNT_DOCK_PUBLIC_KEY: 'ad_acco_u61tcr598hqljyxb',
      API_URL: 'https://api-staging.xplenty.com',
      APP_URL: 'https://app-staging.xplenty.com',
      CONSOLE_URL: 'https://labs-staging.xplenty.com/services/pig/evaluate',
      NODE_ENV: 'staging',
      PUSHER_PUBLIC_KEY: 'ddcc3b4354b94bd5a690',
      SEGMENT_PUBLIC_KEY: 'DF0wuWxKOr6Tb46Nqnyhrf5RaYUkzgGO',
      STRIPE_PUBLIC_KEY: 'pk_test_lGDzBx7kQrJCT71kElzaRXp1',
      PIPEDRIVE_URL: 'https://gateway-staging.xplenty.com/send-pipedrive-leads',
      PIPEDRIVE_RETURN_URL: 'https://www-staging.xplenty.com/calendly/',
      SENTRY_ENV: 'staging',
      SOURCE_VERSION: '806e327ecc5e9239df8264cfb2ecfb7f64f26e20',
      INTEGRATEIO_URL: 'https://dashboard-staging.integrate.io',
      TESTS_FILTER: 'undefined',
      SENTRY_DSN: 'https://75f7d6ae3fb2e8095ed958bc47413e82@o4507576419680256.ingest.us.sentry.io/4507803100577792',
      SENTRY_ENABLED: false,
      CLUSTER_NODES_LIMIT: '20',
      HUBSPOT_URL: 'https://gateway-staging.xplenty.com/send-hubspot-leads',
};
