import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { escape } from 'lodash';
import {
  ColumnMap,
  Connection,
  ConnectionSchemaField,
  Field,
  RestApiDestinationComponentData,
  Schema,
} from '../../package.models';
import { AppState } from '../../../store';
import { setComponentValidity, updateComponent, updateRawComponent } from '../../store/component.actions';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { NotifyService } from '../../../common/services/notify.service';
import {
  AUTHENTICATION_TYPES,
  ComponentTypeItem,
  REST_API_SOURCE_METHODS,
  REST_API_REQUEST_TYPES,
  REST_API_DESTINATION_METHODS,
  NUMBER_OF_REQUESTS,
} from '../../../constants/component_types';
import { getStep } from '../../../common/helper/get-step.helper';
import { Step } from '../../../common/components/xp-steps.component';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { connectionIconUrlByType } from '../../../common/helper/connection-icon-url-by-type.helper';
import { ComponentFormTagsService } from '../../../common/services/component-form-tags.service';
import { AuthorizationGuard } from '../../../common/services/authorization.guard';
import { ValidationsEnum } from 'src/app/common/services/validations.enum';
import { CloudStorageConnectionsQuery } from '../../../connections/connection.models';
import { SelectPickerValue } from '../../../common/components/forms/select-picker/xp-select-picker.component';

@Component({
  selector: 'rest-api-destination-editor',
  template: `
    <div class="rest-api-destination-editor">
      <xp-steps>
        <xp-step [step]="connectionStep">
          <div class="rest-api-authentication">
            <div class="btn-group btn-group-md btn-group-select">
              <button
                type="button"
                class="btn btn-default"
                [attr.value]="AUTHENTICATION_TYPES.NONE"
                name="authentication"
                [ngClass]="{ 'active btn-primary': rawComponent.authentication === AUTHENTICATION_TYPES.NONE }"
                (click)="setAuthenticationType(AUTHENTICATION_TYPES.NONE)"
              >
                None
              </button>
              <button
                type="button"
                class="btn btn-default"
                [attr.value]="AUTHENTICATION_TYPES.BASIC"
                name="authentication"
                [ngClass]="{ 'active btn-primary': rawComponent.authentication === AUTHENTICATION_TYPES.BASIC }"
                (click)="setAuthenticationType(AUTHENTICATION_TYPES.BASIC)"
              >
                Basic
              </button>
              <button
                type="button"
                class="btn btn-default"
                [attr.value]="AUTHENTICATION_TYPES.CONNECTION"
                name="authentication"
                [ngClass]="{ 'active btn-primary': rawComponent.authentication === AUTHENTICATION_TYPES.CONNECTION }"
                (click)="setAuthenticationType(AUTHENTICATION_TYPES.CONNECTION)"
              >
                Connection
              </button>
            </div>
            <div *ngIf="rawComponent.authentication === AUTHENTICATION_TYPES.BASIC">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group lp-ignore">
                    <label for="username">{{ 'rest-api-destination-editor.form.labels.username' | translate }}</label>
                    <input
                      type="text"
                      class="form-control"
                      name="username"
                      id="username"
                      data-lpignore="true"
                      data-1p-ignore="true"
                      [ngModel]="rawComponent.username"
                      (ngModelChange)="onValueChange($event, 'username')"
                      [placeholder]="'rest-api-destination-editor.form.placeholders.username' | translate"
                      autocomplete="off"
                    />
                  </div>
                  <div class="form-group has-feedback lp-ignore">
                    <label for="password">{{ 'rest-api-destination-editor.form.labels.password' | translate }}</label>
                    <input
                      [attr.type]="showPassword ? 'text' : 'password'"
                      class="form-control"
                      name="password"
                      id="password"
                      data-lpignore="true"
                      data-1p-ignore="true"
                      autocomplete="off"
                      [ngModel]="rawComponent.password"
                      (ngModelChange)="onValueChange($event, 'password')"
                      [placeholder]="'rest-api-destination-editor.form.placeholders.password' | translate"
                    />
                    <span class="form-control-feedback">
                      <button class="btn btn-link" (click)="showPassword = !showPassword">
                        <i class="fa" [ngClass]="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="rawComponent.authentication === AUTHENTICATION_TYPES.CONNECTION">
              <label>Choose connection</label>
              <xp-select-picker-editable
                id="connection-picker-rest-api"
                [type]="selectPickerTypes.connection"
                [value]="rawComponent.connection"
                placeholder="Select connection"
                emptyPlaceholder="Connections list is empty"
                (valueChange)="onSelectConnection($event)"
                (createNew)="onCreateNewConnection($event)"
                [params]="{ type: component.connectionTypes }"
                [connectionTypes]="component.connectionTypes.split(',')"
              ></xp-select-picker-editable>
            </div>
          </div>
        </xp-step>
        <xp-step [step]="componentBaseStep" (activate)="onBaseStepActivation()">
          <div class="rest-api-source-editor">
            <xp-form-validation type="Xplenty::JobAuthoring::Components::RestApiTransformationComponent">
              <form name="componentForm" novalidate #form="ngForm">
                <h4>URL and Headers</h4>
                <hr />
                <div class="row">
                  <div class="col-sm-12">
                    <label>Method and URL</label>
                    <xp-form-group innerClass="url-editor input-group" labelName="URL">
                      <label for="method" class="hidden">Method</label>
                      <div class="input-group-btn">
                        <button type="button" class="btn btn-default btn-lg" [matMenuTriggerFor]="dropdown">
                          {{ rawComponent.method }} <span class="caret"></span>
                        </button>
                        <mat-menu #dropdown="matMenu">
                          <li mat-menu-item (click)="onValueChange(REST_API_METHODS.GET, 'method')">
                            {{ 'url-editor.method.get' | translate }}
                          </li>
                          <li mat-menu-item (click)="onValueChange(REST_API_METHODS.POST, 'method')">
                            {{ 'url-editor.method.post' | translate }}
                          </li>
                          <li mat-menu-item (click)="onValueChange(REST_API_METHODS.PATCH, 'method')">
                            {{ 'url-editor.method.patch' | translate }}
                          </li>
                          <li mat-menu-item (click)="onValueChange(REST_API_METHODS.PUT, 'method')">
                            {{ 'url-editor.method.put' | translate }}
                          </li>
                          <li mat-menu-item (click)="onValueChange(REST_API_METHODS.DELETE, 'method')">
                            {{ 'url-editor.method.delete' | translate }}
                          </li>
                        </mat-menu>
                      </div>
                      <label for="url" class="hidden">URL</label>
                      <input
                        type="text"
                        class="form-control"
                        name="url"
                        id="url"
                        [ngModel]="rawComponent.url"
                        (ngModelChange)="onValueChange($event, 'url')"
                        [placeholder]="'url-editor.placeholder' | translate"
                        (blur)="validatePattern(rawComponent.url)"
                      />
                      <div class="input-error-message" *ngIf="urlErrorMessage">{{ urlErrorMessage }}</div>
                      <i
                        *ngIf="rawComponent.number_of_requests === NUMBER_OF_REQUESTS.singlePerRecord"
                        class="fa fa-exclamation-circle"
                        matTooltip="You can customize your URL by dynamically inserting values from records using the #{fieldName} pattern. To use this feature, simply specify the field name inside curly braces within the URL. For example 'https://your-service.com/#{id}/details'. It cannot be applied if batch option is enabled."
                        matTooltipPosition="above"
                        matTooltipClass="above wide-400"
                      ></i>
                    </xp-form-group>
                  </div>
                </div>
                <div class="row">
                  <rest-api-headers-collection
                    [records]="rawComponent.headers"
                    (recordsChange)="onValueChange($event, 'headers')"
                    (validityChange)="onHeadersValidityChange($event)"
                  >
                  </rest-api-headers-collection>
                </div>
                <h4>Body</h4>
                <hr />
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group response-type-container">
                      <label for="response_type">Request format</label>
                      <div class="btn-group btn-group-md btn-group-select">
                        <button
                          type="button"
                          class="btn btn-default"
                          [ngClass]="{ active: rawComponent.request_type === REST_API_REQUEST_TYPES.json }"
                          value="json"
                          (click)="onValueChange(REST_API_REQUEST_TYPES.json, 'request_type')"
                        >
                          {{ 'rest-api-destination-editor.form.selects.request_type.options.json' | translate }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          [ngClass]="{ active: rawComponent.request_type === REST_API_REQUEST_TYPES.csv }"
                          value="raw"
                          (click)="onValueChange(REST_API_REQUEST_TYPES.csv, 'request_type')"
                        >
                          {{ 'rest-api-destination-editor.form.selects.request_type.options.csv' | translate }}
                        </button>
                        <button
                          *ngIf="
                            rawComponent.number_of_requests !== NUMBER_OF_REQUESTS.batch ||
                            rawComponent.is_custom_payload
                          "
                          type="button"
                          class="btn btn-default"
                          [ngClass]="{ active: rawComponent.request_type === REST_API_REQUEST_TYPES.formData }"
                          value="raw"
                          (click)="onValueChange(REST_API_REQUEST_TYPES.formData, 'request_type')"
                        >
                          {{ 'rest-api-destination-editor.form.selects.request_type.options.form-data' | translate }}
                        </button>
                        <button
                          *ngIf="
                            rawComponent.number_of_requests !== NUMBER_OF_REQUESTS.batch ||
                            rawComponent.is_custom_payload
                          "
                          type="button"
                          class="btn btn-default"
                          [ngClass]="{ active: rawComponent.request_type === REST_API_REQUEST_TYPES.urlEncoded }"
                          value="raw"
                          (click)="onValueChange(REST_API_REQUEST_TYPES.urlEncoded, 'request_type')"
                        >
                          {{ 'rest-api-destination-editor.form.selects.request_type.options.url-encoded' | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group response-type-container">
                      <label for="response_type">Payload type</label>
                      <div class="btn-group btn-group-md btn-group-select">
                        <button
                          type="button"
                          class="btn btn-default"
                          [ngClass]="{ active: !rawComponent.is_custom_payload }"
                          value="json"
                          (click)="onValueChange(false, 'is_custom_payload')"
                        >
                          {{ 'rest-api-destination-editor.form.selects.payload_type.options.records' | translate }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          [ngClass]="{ active: !!rawComponent.is_custom_payload }"
                          value="raw"
                          (click)="onValueChange(true, 'is_custom_payload')"
                        >
                          {{ 'rest-api-destination-editor.form.selects.payload_type.options.custom_input' | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row"
                  *ngIf="
                    (rawComponent.request_type !== REST_API_REQUEST_TYPES.urlEncoded &&
                      rawComponent.request_type !== REST_API_REQUEST_TYPES.formData) ||
                    rawComponent.is_custom_payload
                  "
                >
                  <div class="col-sm-12">
                    <div class="form-group response-type-container">
                      <label for="response_type">Number of requests</label>
                      <div class="btn-group btn-group-md btn-group-select">
                        <button
                          *ngIf="
                            rawComponent.request_type !== REST_API_REQUEST_TYPES.csv || rawComponent.is_custom_payload
                          "
                          type="button"
                          class="btn btn-default"
                          [ngClass]="{ active: rawComponent.number_of_requests === NUMBER_OF_REQUESTS.singlePerRecord }"
                          value="json"
                          (click)="onValueChange(NUMBER_OF_REQUESTS.singlePerRecord, 'number_of_requests')"
                        >
                          {{
                            'rest-api-destination-editor.form.selects.number_of_requests.options.single_per_record'
                              | translate
                          }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          [ngClass]="{ active: rawComponent.number_of_requests === NUMBER_OF_REQUESTS.batch }"
                          value="raw"
                          (click)="onValueChange(NUMBER_OF_REQUESTS.batch, 'number_of_requests')"
                        >
                          {{ 'rest-api-destination-editor.form.selects.number_of_requests.options.batch' | translate }}
                        </button>
                        <button
                          type="button"
                          class="btn btn-default"
                          [ngClass]="{ active: rawComponent.number_of_requests === NUMBER_OF_REQUESTS.oneRequest }"
                          value="raw"
                          (click)="onValueChange(NUMBER_OF_REQUESTS.oneRequest, 'number_of_requests')"
                        >
                          {{
                            'rest-api-destination-editor.form.selects.number_of_requests.options.one_request'
                              | translate
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="rawComponent.number_of_requests === NUMBER_OF_REQUESTS.batch">
                  <div class="col-md-6">
                    <xp-form-group>
                      <label for="batch_size">{{
                        'rest-api-destination-editor.form.labels.batch_size' | translate
                      }}</label>
                      <xp-input
                        type="number"
                        name="batch_size"
                        id="batch_size"
                        [ngModel]="rawComponent.batch_size"
                        (ngModelChange)="onValueChange($event, 'batch_size')"
                        class="form-control"
                      ></xp-input>
                    </xp-form-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <xp-input-checkbox
                      [ngModel]="rawComponent.fail_on_error_response"
                      name="fail_on_error_response"
                      [labelText]="'rest-api-destination-editor.form.labels.fail_on_error_response' | translate"
                      (ngModelChange)="onValueChange($event, 'fail_on_error_response')"
                    ></xp-input-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <xp-form-group>
                      <xp-input-checkbox
                        name="save_responses_to_file"
                        [ngModel]="rawComponent.save_responses_to_file"
                        (ngModelChange)="onValueChange($event, 'save_responses_to_file')"
                        [labelText]="'rest-api-destination-editor.form.labels.save_responses_to_file' | translate"
                      ></xp-input-checkbox>
                    </xp-form-group>
                  </div>
                </div>
                <div class="row" *ngIf="rawComponent.save_responses_to_file">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label for="responses_output_connection">{{
                        'rest-api-destination-editor.form.labels.responses_output_connection' | translate
                      }}</label>

                      <xp-select-picker-editable
                        id="responses_output_connection"
                        [type]="selectPickerTypes.connection"
                        [value]="rawComponent.responses_output_connection"
                        placeholder="Select connection"
                        emptyPlaceholder="Connections list is empty"
                        [params]="{ type: selectPickerConnectionTypes }"
                        (valueChange)="onSelectResponsesOutputConnection($event)"
                        [hideNew]="true"
                        (createNew)="onCreateNewConnection($event)"
                      ></xp-select-picker-editable>
                    </div>
                    <xp-form-group>
                      <label for="responses_output_path">{{
                        'rest-api-destination-editor.form.labels.responses_output_path' | translate
                      }}</label>
                      <xp-input
                        type="text"
                        class="form-control"
                        name="responses_output_path"
                        id="responses_output_path"
                        [ngModel]="rawComponent.responses_output_path"
                        (ngModelChange)="onValueChange($event, 'responses_output_path')"
                        placeholder="bucket/path/to/errors/output/"
                      ></xp-input>
                    </xp-form-group>
                  </div>
                </div>
              </form>
            </xp-form-validation>
          </div>
        </xp-step>
        <xp-step [step]="schemaMappingStep" (activate)="isSchemaMappingStepActivated = true">
          <schema-mapping-rest-api-collection
            [rawComponent]="rawComponent"
            [records]="rawComponent.column_mappings"
            [parentSchemas]="parentSchemas"
            (validityChange)="onFieldsValidityChange($event)"
            (recordsChange)="onRecordsChange($event)"
            (constsRecordsChange)="onConstsRecordsChange($event)"
            [componentType]="component.componentType"
            [isBatch]="
              rawComponent.number_of_requests === NUMBER_OF_REQUESTS.batch ||
              rawComponent.number_of_requests === NUMBER_OF_REQUESTS.oneRequest
            "
            [requestType]="rawComponent.request_type"
            [isCustomPayload]="rawComponent.is_custom_payload"
          ></schema-mapping-rest-api-collection>
        </xp-step>
      </xp-steps>
    </div>
  `,
})
export class RestApiDestinationEditorComponent extends BaseForm implements BaseFormInterface, OnChanges {
  @Input() rawComponent: RestApiDestinationComponentData;
  @Input() component: ComponentTypeItem;
  @Input() parentSchemas: Schema[];
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() createConnection = new EventEmitter();
  @ViewChild('form') form: NgForm;
  formName = 'componentForm';
  successMessageText = '';
  urlErrorMessage = '';

  selectPickerTypes = SelectPickerTypes;

  fieldsCollectionValid = true;
  isFormValid = true;
  validationChangeSubscription: Subscription;

  connectionStep: Step = getStep({ active: true });
  componentBaseStep: Step = getStep({});
  schemaMappingStep: Step = getStep({});

  showPassword = false;
  areHeadersValid = true;
  isBaseComponentStepActivated = false;
  ValidationsEnum = ValidationsEnum;
  isSchemaMappingStepActivated = false;

  AUTHENTICATION_TYPES = AUTHENTICATION_TYPES;
  REST_API_METHODS = REST_API_DESTINATION_METHODS;
  REST_API_REQUEST_TYPES = REST_API_REQUEST_TYPES;
  NUMBER_OF_REQUESTS = NUMBER_OF_REQUESTS;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private componentFormTagsService: ComponentFormTagsService,
    private authGuard: AuthorizationGuard,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.validationChangeSubscription = this.formValidationChange.subscribe((isFormValid) => {
      this.isFormValid = isFormValid;

      this.componentBaseStep = {
        ...this.componentBaseStep,
        valid: isFormValid,
        isError: this.isBaseComponentStepActivated && !isFormValid,
        tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
      };
      this.onValidityChange();
    });

    this.connectionStep = getStep({
      title: this.translate.instant(`component-editor.step-connection.${this.component.type}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-connection.${this.component.type}.active`),
      valid: true,
      active: true,
    });

    this.componentBaseStep = getStep({
      title: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(`component-editor.step-editor.${this.component.componentType}.active`),
      valid: !!this.rawComponent.connection?.id,
      tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
    });

    this.schemaMappingStep = getStep({
      title: this.translate.instant(`component-editor.step-schema-mapping.${this.component.componentType}.closed`),
      activeTitle: this.translate.instant(
        `component-editor.step-schema-mapping.${this.component.componentType}.active`,
      ),
      tags: (this.rawComponent.column_mappings || []).map((record) => ({
        name: escape(record.column_name),
      })),
    });

    if (this.rawComponent.custom_payload) {
      this.onValueChange(true, 'is_custom_payload');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.componentBaseStep = {
      ...this.componentBaseStep,
      tags: this.componentFormTagsService.getTags(this.rawComponent, this.component),
    };

    if (this.rawComponent.is_custom_payload && !!this.rawComponent.custom_payload) {
      this.schemaMappingStep = {
        ...this.schemaMappingStep,
        valid: true,
        tags: [
          {
            name: 'Custom Payload',
          },
        ],
      };
    }
  }

  onBaseStepActivation() {
    this.isBaseComponentStepActivated = true;
  }

  onFieldsValidityChange(value) {
    const isSchemaValid = value && this.connectionStep.valid && this.isFormValid;
    if (!this.isSchemaMappingStepActivated && !isSchemaValid) {
      return;
    }

    this.fieldsCollectionValid = value;

    this.schemaMappingStep = {
      ...this.schemaMappingStep,
      valid: this.fieldsCollectionValid && this.connectionStep.valid && this.isFormValid,
      isError: !this.fieldsCollectionValid,
    };

    this.onValidityChange();
  }
  onHeadersValidityChange(areHeadersValid: boolean) {
    this.areHeadersValid = areHeadersValid;

    this.onValidityChange();
  }

  onValidityChange(isValidParam = true) {
    const isValid = this.fieldsCollectionValid && this.isFormValid && this.areHeadersValid && isValidParam;

    this.store.dispatch(setComponentValidity({ isComponentFormValid: isValid }));
  }

  onSelectConnection(connection: Partial<Connection>) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { connection },
      }),
    );
    this.store.dispatch(updateComponent({ component: { connection } }));

    const img = `<img class="tag-icon" src="${connectionIconUrlByType(connection.type)}" alt="${connection.name}" />`;

    this.connectionStep.tags = [
      {
        name: `${img}<b>${escape(connection.name)}</b>`,
      },
    ];

    this.connectionStep = { ...this.connectionStep, valid: true };
    this.componentBaseStep = { ...this.componentBaseStep, valid: this.isFormValid };
  }

  onCreateNewConnection(params) {
    this.createConnection.emit(params);
  }

  onValueChange(value: any, key: string) {
    if (
      key === 'request_type' &&
      value === REST_API_REQUEST_TYPES.csv &&
      this.rawComponent.number_of_requests === NUMBER_OF_REQUESTS.singlePerRecord
    ) {
      this.onValueChange(NUMBER_OF_REQUESTS.batch, 'number_of_requests');
    }

    if (key === 'number_of_requests') {
      setTimeout(() => {
        this.validatePattern(this.rawComponent.url);
      });

      if (
        this.rawComponent.request_type !== REST_API_REQUEST_TYPES.json &&
        this.rawComponent.request_type !== REST_API_REQUEST_TYPES.csv
      ) {
        this.onValueChange(REST_API_REQUEST_TYPES.json, 'request_type');
      }
    }

    if (key === 'is_custom_payload' && !value) {
      this.onValueChange('', 'custom_payload');
    }

    this.store.dispatch(
      updateRawComponent({
        rawComponent: { [key]: value },
      }),
    );
    this.store.dispatch(updateComponent({ component: { [key]: value } }));
  }

  onRecordsChange(records) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { column_mappings: records },
      }),
    );
    this.store.dispatch(updateComponent({ component: { column_mappings: records } }));

    this.schemaMappingStep = {
      ...this.schemaMappingStep,
      tags: this.fieldsCollectionValid
        ? records.map((record) => ({
            name: escape(record.column_name),
          }))
        : [],
    };
  }

  onConstsRecordsChange(records) {
    this.store.dispatch(
      updateRawComponent({
        rawComponent: { json_consts: records },
      }),
    );
    this.store.dispatch(updateComponent({ component: { json_consts: records } }));
  }

  onSelectResponsesOutputConnection(value: SelectPickerValue) {
    const connection = value as Connection;
    this.onValueChange(
      { id: connection.id, name: connection.name, type: connection.type },
      'responses_output_connection',
    );
  }

  setAuthenticationType(authenticationType: string) {
    this.onValueChange(authenticationType, 'authentication');
    if (authenticationType !== AUTHENTICATION_TYPES.CONNECTION) {
      this.onValueChange({}, AUTHENTICATION_TYPES.CONNECTION);
    }
    if (authenticationType !== AUTHENTICATION_TYPES.BASIC) {
      this.onValueChange(null, 'username');
      this.onValueChange(null, 'password');
    }
  }

  validatePattern(value: string) {
    const regex = /#\{([^\}]+)\}/g;
    const matches = value.matchAll(regex);

    for (const match of matches) {
      const foundField = ((this.parentSchemas[0] || {}).fields || []).find((field) => field.name === match[1]);

      if (!foundField) {
        this.urlErrorMessage = `Field ${match[1]} not found in schema`;
      }

      if (this.rawComponent.number_of_requests !== NUMBER_OF_REQUESTS.singlePerRecord) {
        this.urlErrorMessage = 'Single per Record is not set. URL pattern is not allowed';
      }

      if (foundField && this.rawComponent.number_of_requests === NUMBER_OF_REQUESTS.singlePerRecord) {
        this.urlErrorMessage = '';
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    if (this.validationChangeSubscription) {
      this.validationChangeSubscription.unsubscribe();
    }
  }

  protected readonly selectPickerConnectionTypes = CloudStorageConnectionsQuery;
}
