import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import { HerokuPostgresConnection } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { selectConnectionsErrors } from '../../store/connections.selectors';

@Component({
  selector: 'connection-form-heroku-postgres',
  template: `
    <xp-form-validation type="HerokuPostgresDatabaseConnection" [name]="formName">
      <form id="connectionFormHerokuPostgres" name="connectionFormHerokuPostgres" #form="ngForm" class="form">
        <div class="row">
          <div class="col-md-6">
            <xp-form-group>
              <label for="name">{{ 'connections.form.labels.name' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="name"
                id="name"
                [(ngModel)]="connection.name"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <div class="form-group" *ngIf="connection.unique_id">
              <label for="unique_id">{{ 'connections.form.labels.unique_id' | translate }}</label>
              <xp-input
                type="text"
                class="form-control"
                name="unique_id"
                id="unique_id"
                [(ngModel)]="connection.unique_id"
                [attr.readonly]="true"
                [readOnly]="true"
              ></xp-input>
            </div>
            <connection-component-access-type
              [connection]="connection"
              (tunnelTypeChange)="onTunnelTypeChange($event)"
            ></connection-component-access-type>
            <div class="connection-ssl">
              <xp-form-group [validationDisabled]="true">
                <xp-input-checkbox
                  name="ssl"
                  [(ngModel)]="connection.ssl"
                  [labelText]="'connections.form.labels.connect_using_ssl' | translate"
                ></xp-input-checkbox>
              </xp-form-group>
            </div>
          </div>
          <div class="col-md-6">
            <xp-form-group>
              <label for="database"
                >{{ 'connections.form.labels.database' | translate }}
                <span class="text-muted">{{ 'generic.labels.optional' | translate }}</span></label
              >
              <xp-input
                type="text"
                class="form-control"
                name="database"
                id="database"
                [(ngModel)]="connection.database"
                placeholder=""
              ></xp-input>
            </xp-form-group>
            <connection-component-username-password [connection]="connection"></connection-component-username-password>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <xp-form-group [validationDisabled]="true">
              <xp-input-checkbox
                name="ssl"
                [ngModel]="connection.is_using_certificate"
                (ngModelChange)="onCertificateFlagChange($event)"
                [labelText]="'connections.form.labels.connect_using_certificate' | translate"
              ></xp-input-checkbox>
            </xp-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="files-input-container">
              <xp-form-group *ngIf="connection.is_using_certificate">
                <label for="cert_file">{{ 'connections.form.labels.certificate_file' | translate }}</label>
                <input
                  style="display: none"
                  type="file"
                  (change)="onFileChangedCert($event)"
                  id="cert_file"
                  name="cert_file"
                  #fileInput
                />
                <button class="btn btn-default form-control btn-info" (click)="fileInput.click()">
                  {{ selectedFileCert ? 'Select another file' : 'Select file' }}
                </button>
                <span class="file-name" *ngIf="selectedFileCert">{{ selectedFileCert.name | xpLengthCheck: 28 }}</span>
                <span class="error-message" *ngIf="errorCert">{{ errorCert }}</span>
              </xp-form-group>
            </div>
          </div>
          <div class="col-md-4">
            <div class="files-input-container">
              <xp-form-group *ngIf="connection.is_using_certificate">
                <label for="cert_file">{{ 'connections.form.labels.certificate_file_key' | translate }}</label>
                <input
                  style="display: none"
                  type="file"
                  (change)="onFileChangedKey($event)"
                  id="cert_file"
                  name="cert_file"
                  #fileInput
                />
                <button class="btn btn-default form-control btn-info" (click)="fileInput.click()">
                  {{ selectedFileKey ? 'Select another file' : 'Select file' }}
                </button>
                <span class="file-name" *ngIf="selectedFileKey">{{ selectedFileKey.name | xpLengthCheck: 28 }}</span>
                <span class="error-message" *ngIf="errorKey">{{ errorKey }}</span>
              </xp-form-group>
            </div>
          </div>
          <div class="col-md-4">
            <div class="files-input-container">
              <xp-form-group *ngIf="connection.is_using_certificate">
                <label for="cert_file">{{ 'connections.form.labels.certificate_file_root' | translate }}</label>
                <input
                  style="display: none"
                  type="file"
                  (change)="onFileChangedRoot($event)"
                  id="cert_file"
                  name="cert_file"
                  #fileInput
                />
                <button class="btn btn-default form-control btn-info" (click)="fileInput.click()">
                  {{ selectedFileRoot ? 'Select another file' : 'Select file' }}
                </button>
                <span class="file-name" *ngIf="selectedFileRoot">{{ selectedFileRoot.name | xpLengthCheck: 28 }}</span>
                <span class="error-message" *ngIf="errorRoot">{{ errorRoot }}</span>
              </xp-form-group>
            </div>
          </div>
        </div>
      </form>
    </xp-form-validation>
  `,
})
export class ConnectionFormHerokuPostgresComponent extends BaseForm implements BaseFormInterface {
  @Input() connection: Partial<HerokuPostgresConnection> = {};
  @Output() formValidationChange = new EventEmitter<boolean>();
  @Output() tunnelTypeChange = new EventEmitter<String>();

  @ViewChild('form') form: NgForm;
  formName = 'connectionFormHerokuPostgres';
  successMessageText = 'connection.form.success_message';
  errors$ = this.store.select(selectConnectionsErrors);
  errorTexts = [];
  selectedFileCert: Blob;
  selectedFileKey: Blob;
  selectedFileRoot: Blob;
  errorCert: string;
  errorKey: string;
  errorRoot: string;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
  ) {
    super();
  }

  onTunnelTypeChange(value: string) {
    this.tunnelTypeChange.emit(value);
  }

  onCertificateFlagChange(value: boolean) {
    this.connection.is_using_certificate = value;
  }

  onFileChangedCert(event: Event) {
    this.errorCert = '';

    // eslint-disable-next-line prefer-destructuring
    this.selectedFileCert = (event.target as HTMLInputElement).files[0];
    const fileReader = new FileReader();

    if (this.selectedFileCert?.type !== 'application/x-x509-ca-cert') {
      this.errorCert = 'Selected file is not a PEM type.';
      this.selectedFileCert = null;
      return;
    }

    fileReader.readAsText(this.selectedFileCert, 'UTF-8');

    fileReader.onload = () => {
      try {
        this.connection.ssl_certificate = fileReader.result as string;
      } catch (e) {
        this.errorCert = e;
      }
    };

    fileReader.onerror = (error) => {
      this.errorCert = JSON.stringify(error);
    };
  }

  onFileChangedKey(event: Event) {
    this.errorKey = '';

    // eslint-disable-next-line prefer-destructuring
    this.selectedFileKey = (event.target as HTMLInputElement).files[0];
    const fileReader = new FileReader();

    if (this.selectedFileKey?.type !== 'application/x-iwork-keynote-sffkey') {
      this.errorKey = 'Selected file is not a Key type.';
      this.selectedFileKey = null;
      return;
    }
    fileReader.readAsText(this.selectedFileKey, 'UTF-8');

    fileReader.onload = () => {
      try {
        this.connection.ssl_private_key = fileReader.result as string;
      } catch (e) {
        this.errorKey = e;
      }
    };

    fileReader.onerror = (error) => {
      this.errorKey = JSON.stringify(error);
    };
  }

  onFileChangedRoot(event: Event) {
    this.errorRoot = '';

    // eslint-disable-next-line prefer-destructuring
    this.selectedFileRoot = (event.target as HTMLInputElement).files[0];
    const fileReader = new FileReader();

    if (this.selectedFileRoot?.type !== 'application/x-x509-ca-cert') {
      this.errorRoot = 'Selected file is not a PEM type.';
      this.selectedFileRoot = null;
      return;
    }

    fileReader.readAsText(this.selectedFileRoot, 'UTF-8');

    fileReader.onload = () => {
      try {
        this.connection.ssl_ca_certificate = fileReader.result as string;
      } catch (e) {
        this.errorRoot = e;
      }
    };

    fileReader.onerror = (error) => {
      this.errorRoot = JSON.stringify(error);
    };
  }
}
